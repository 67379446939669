import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./Contact.css";

function Contact() {
  const form = useRef();
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [showForm, setShowForm] = useState(false); // Toggle for the form visibility

  const sendEmail = (e) => {
    e.preventDefault();

    if (isEmailSent) {
      console.log("Please wait before sending another email.");
      return;
    }

    emailjs
      .sendForm("service_7l601rm", "template_bx5ftbi", form.current, {
        publicKey: "cinrza-JW55XSpf6N",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          setIsEmailSent(true);
          setTimeout(() => {
            setIsEmailSent(false);
          }, 120000);
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  const toggleFormVisibility = () => {
    setShowForm(!showForm);
  };

  return (
    <div className="footer-main-container">
      <footer>
        <div className="footer-container">
          {/* <div className="footer-logo-container">
            <img
              src={`${process.env.PUBLIC_URL}/images/amongus.png`}
              alt="Logo"
              id="sussybaka"
            />
          </div> */}

          <div className="footer-contact-section">
            {!showForm ? (
              <div className="contact-details">
                <h4>Contact Me</h4>
                <div className="email-btn"></div>
                <p>Phone: 9863420751</p>

                <div>
                  <link
                    href="https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap"
                    rel="stylesheet"
                  />
                  <main className="content" data-form-type="card">
                    <a
                      href="javascript:;"
                      className="btn"
                      onClick={toggleFormVisibility}
                    >
                      <span className="btn__circle"></span>
                      <span className="btn__white-circle">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          id="icon-arrow-right"
                          viewBox="0 0 21 12"
                        >
                          <path d="M17.104 5.072l-4.138-4.014L14.056 0l6 5.82-6 5.82-1.09-1.057 4.138-4.014H0V5.072h17.104z" />
                        </svg>
                      </span>
                      <span className="btn__text">bipeensubedi@gmail.com</span>
                    </a>
                  </main>
                </div>
              </div>
            ) : (
            
                <form ref={form} onSubmit={sendEmail} className="footer-contact-form">
                  <div>
                    <div className="form-group">
                      <label>Name</label>
                      <input type="text" name="user_name" required />
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      <input type="email" name="user_email" required />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Message</label>
                    <textarea name="message" required />
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <input type="submit" value="Send" />{" "}
                    <button
                      className="toggle-form-cancel-btn"
                      onClick={toggleFormVisibility}
                      style={{ backgroundColor: "red" }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
        
            )}

            {/* {!showForm && (
              <button
                className="toggle-form-btn"
                onClick={toggleFormVisibility}
              >
                Send a Mail
              </button>
            )} */}
          </div>

          <div className="social-media-container">
            <a href="#navbar" className="btn-container" role="button">
              <a href="#" className="button button--pen">
                <div className="button__wrapper">
                  <span className="button__text">MY Socials</span>
                </div>
                <div className="characterBox">
                  <div className="character wakeup">
                    <div className="character__face"></div>
                    <div className="charactor__face2"></div>
                  </div>
                  <div className="character wakeup">
                    <div className="character__face"></div>
                    <div className="charactor__face2"></div>
                  </div>
                  <div className="character">
                    <div className="character__face"></div>
                    <div className="charactor__face2"></div>
                  </div>
                </div>
              </a>
            </a>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 Bipin Subedi. All Rights Reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default Contact;
