import "./App.css";
import AboutMe from "./component/AboutMe";
import Contact from "./component/Contact";
import Homepage from "./component/HomePage";
import Navbar from "./component/Navbar";
import Project from "./component/Project";
import Skills from "./component/Skills";

import React, { useRef } from "react";


function App() {
 

 

  return (
    <>
      <Navbar />
      <Homepage />
      <AboutMe />
      <Skills/>
      <Project/>
      <Contact/>
    </>
  );
}

export default App;
