import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

import "./Navbar.css";
import { useEffect, useState } from "react";
// import DarkMode from '../component/DarkMode/DarkMode/DarkMode';

function Navbar() {
  const [theme, setTheme] = useState("dark-theme");
  const ToggleTheme = () => {
    if (theme === "light-theme") {
      setTheme("dark-theme");
    } else {
      setTheme("light-theme");
    }
  };
  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  return (
    <>
      <nav className="Navbar" id="navbar">
        <a href="#about-me" className="logo-container">
          <img className="logo" src={`${process.env.PUBLIC_URL}/images/logo1.png`} alt="" />
          <p className="logo-text">Bipin Subedi</p>
        </a>

        <div className="right-nav">
          <a id="icon" href="https://www.facebook.com/bpin.subedi" target="_blank">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a id="icon" href="https://www.instagram.com/bipeensubedi" target="_blank">
            {" "}
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a id="icon" href="https://www.linkedin.com/in/bipin-subedi-a5a8272aa" target="_blank">
          
            <FontAwesomeIcon id="icon" icon={faLinkedin} />
          </a>
          <div className="darkmode-container">
            <input
              type="checkbox"
              id="check"
              className="Dark-mode-btn"
              onClick={() => ToggleTheme()}
            ></input>
            <label htmlFor="check"></label>
          </div>
        </div>
      </nav>
    </>
  );
}
export default Navbar;
