import React from "react";
import "./Project.css";

function Project() {
  return (
    <section className="projects">
      <h1 className="projects-heading">Work</h1>
      <div className="project-card-container">
        <div className="project-card">
          <img src={`${process.env.PUBLIC_URL}/images/project1.png`} alt="" />
          <div className="project-content">
            <h1>Travel Website</h1>
          </div>
        </div>
        <div className="project-card">
          <a
            // href="http://13.210.134.70"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={`${process.env.PUBLIC_URL}/images/project2.png`} alt="GasSewa Project" />
          <div className="project-content">
            <h1>GasSewa</h1>
          </div>
          </a>
        </div>

        <div className="project-card">
          {" "}
          <img src={`${process.env.PUBLIC_URL}/images/project3.png`}alt="" />
          <div className="project-content">
            <h1>Car Rental Website</h1>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Project;
